@import './theme.scss';
html {
    @apply overflow-x-hidden;
}
body {
    font-family: 'Open Sans';
    // font-family: 'Lato';
    .app-container {
        transition: opacity 1s ease-in;
        .app-content-wrapper {
            @apply flex flex-col 2xl:px-44 xl:px-36 lg:px-24 md:px-16 px-8 opacity-100;
            .section-header-icon {
                @apply mr-3;
            }
        }
        &.content-loading {
            @apply h-0 w-0 opacity-0;
        }
    }
    @apply text-white-light bg-blue overflow-x-hidden;
}
* {
    // scroll-behavior: smooth;
    // overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    @apply bg-primary;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    @apply bg-blue;
    &:hover {
        @apply bg-blue;
    }
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    border-radius: 12px;
    border: 3px solid $blue;
    @apply bg-white-light;
}
::-webkit-scrollbar-thumb:hover {
    @apply bg-white-light;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

::selection {
    transition: background-color 0.35s;
    @apply bg-white-thin;
}
