@import '../theme.scss';
.contact-module {
    width: 100%;
    .contact-wrapper {
        max-width: 550px;
        .title {
            svg {
                @apply  md:text-2xl text-xl
            }
            @apply w-full md:text-3xl text-2xl font-bold flex flex-row items-center pb-8 pr-4 text-white;
        }
        .content {
            @apply text-base font-light text-center text-white;
        }
        .contact-action-button {
            @apply md:text-base text-sm text-primary mt-6 inline-block px-5;
        }
        .footer {
            .technologies-list {
                display: grid;
                grid-template-columns: repeat(2, minmax(150px, 200px));
                li {
                    @apply text-sm pl-5 flex items-center;
                    font-family: monospace;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: '▹';
                        left: 0px;
                        line-height: 12px;
                        @apply text-xs text-primary;
                    }
                    @apply pb-1;
                }
            }
            @apply text-white-light;
        }
        @apply h-full flex flex-col items-center justify-center;
    }
    .app-footer {
        @apply self-end;
    }
    @apply flex flex-col h-screen items-center justify-center;
}
