@import '../theme.scss';
.project-card-wrapper {
    grid-template-rows: auto 1fr auto;
    box-shadow: 0 10px 30px -15px $card-shadow;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: $card-border-radius;
    .project-card-header {
        grid-column: 1 / 2;
        grid-template-columns: 1fr auto auto;
        .project-main-icon {
            @apply text-2xl text-primary;
        }
        .project-git-icon {
            @apply text-xl cursor-pointer text-white;
        }
        .project-demo-icon {
            @apply ml-5 text-xl cursor-pointer text-white;
        }
        @apply grid items-center mb-5;
    }
    .project-card-title {
        grid-column: 1 / 2;
        @apply font-bold text-xl mb-3 text-white justify-self-start;
    }
    .project-card-content {
        grid-column: 1 / 2;
        @apply text-base mb-4 text-white-light;
    }
    .project-card-footer {
        grid-column: 1 / 2;
        .project-card-tech {
            .project-card-tech-item {
                font-family: monospace;
                @apply text-xs pr-3;
            }
            @apply flex flex-row flex-wrap;
        }
    }
    @apply p-6 grid bg-blue-light;
}
