@import './theme.scss';
.hover-underline-animation {
    cursor: pointer;
    position: relative;
    display: inline-block;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: $primary;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
    &:hover {
        color: $primary !important;
        &:after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
}

.app-button {
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    @apply px-4 py-3;
    transition: background-color 0.3s;
    &:hover {
        background-color: rgba($color: $primary, $alpha: 0.1);
    } 
}
