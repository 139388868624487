@import '../theme.scss';
.email-wrapper {
    position: fixed;
    @apply flex-col items-center justify-center bottom-0 lg:right-8 right-6 md:flex hidden;
    .email-link {
        writing-mode: vertical-rl;
        transition: all 0.3s;
        &:hover {
            @apply mb-4 text-primary;
        }
        @apply p-3 mb-3 text-xs text-white;
    }
    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 90px;
        margin: 0px auto;
        @apply bg-white;
    }
    z-index: 2;
}
