@import '../theme.scss';
.featured-project-card-wrapper {
    @media only screen and (max-width: 767px) {
        box-shadow: 0 10px 30px -15px $card-shadow;
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    &.featured-align-left {
        .featured-thumb {
            @apply row-start-1 row-end-2;
            @apply lg:col-start-6 lg:col-end-13 md:col-start-6 md:col-end-13 col-start-1 col-end-13;
        }
        .featured-content {
            .featured-title {
                @apply text-left;
            }
            .featured-header {
                @apply text-left;
            }
            .featured-technologies {
                .featured-technology {
                    @apply pl-0 pr-4;
                }
                @apply text-left flex-row md:justify-start;
            }
            .featured-footer {
                @apply items-start;
            }
            @apply row-start-1 row-end-2;
            @apply lg:col-start-1 lg:col-end-7 md:col-start-1 md:col-end-9 col-start-1 col-end-13;
        }
    }
    .featured-thumb {
        position: relative;
        & > * {
            border-radius: $card-border-radius;
        }
        .thumb-filter {
            // md
            @media only screen and (min-width: 768px) {
                opacity: 0.3;
                background-color: #1e79ff;
            }
            top: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0.9;
            transition: background-color 0.35s;
            &:hover {
                background-color: transparent;
            }
            @apply bg-blue-light;
        }
        img {
            @apply md:w-auto w-full md:h-auto h-full md:object-contain object-cover;
        }
        @apply md:h-auto h-full;
        @apply row-start-1 row-end-2;
        @apply lg:col-start-1 lg:col-end-8 md:col-start-1 md:col-end-8 col-start-1 col-end-13;
    }
    .featured-content {
        & > * {
            position: relative;
        }
        .featured-title {
            font-family: monospace;
            @apply block text-sm pb-2 md:text-right text-primary;
        }
        .featured-header {
            transition: color 0.35s;
            cursor: pointer;
            &:hover {
                @apply text-primary;
            }
            @apply font-bold md:text-2xl text-xl mb-4 md:text-right text-white block;
        }
        .featured-description {
            transition: all 0.35s;
            z-index: 2;
            padding: 25px;
            border-radius: $card-border-radius;
            &:hover {
                @media only screen and (min-width: 768px) {
                    box-shadow: 0 20px 30px -15px $card-shadow;
                }
                @apply text-white;
            }
            // md
            @media only screen and (min-width: 768px) {
                box-shadow: 0 10px 30px -15px $card-shadow;
            }
            @apply mb-5 text-white-light md:bg-blue-light bg-transparent;
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }
        .featured-technologies {
            font-family: monospace;
            .featured-technology {
                transition: color 0.35s;
                cursor: pointer;
                &:hover {
                    @apply text-white-light;
                }
                @apply text-xs pl-4 text-white;
            }
            @apply text-sm pb-4 md:text-right;
            @apply flex md:flex-row md:justify-end flex-row flex-wrap;
        }
        .featured-footer {
            .featured-git-icon {
                bottom: 0px;
                left: 0px;
                transition: color 0.35s;
                cursor: pointer;
                &:hover {
                    @apply text-primary;
                }
            }
            @apply flex flex-col items-end text-xl;
        }
        @apply md:p-0 p-6;
        @apply row-start-1 row-end-2;
        @apply lg:col-start-7 lg:col-end-13 md:col-start-5 md:col-end-13 col-start-1 col-end-13;
    }
    @apply grid grid-cols-12 items-center md:mb-24 mb-8;
}
