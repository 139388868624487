@import '../theme.scss';
.links-wrapper {
    position: fixed;
    @apply text-lg flex-col items-center justify-center bottom-0 lg:left-8 left-6 md:flex hidden;
    .icon {
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
            @apply mb-4 mt-2 text-primary;
        }
        &.disabled {
            cursor: not-allowed;
        }
        @apply text-white;
    }
    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 90px;
        margin: 0px auto;
        @apply bg-white;
    }
    z-index: 2;
}
