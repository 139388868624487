@import '../theme.scss';
.home-module {
    #tsparticles {
        z-index: 0;
        @apply left-0 top-0 absolute h-full w-full;
        &>canvas {
            position: relative !important;
        }
    }
    min-height: 100vh;
    width: 100%;
    @apply flex items-center justify-center;
    .welcome-measage {
        @apply p-12;
        .header {
            @apply md:text-base text-sm pb-3 text-primary;
        }
        .title-1 {
            @apply md:text-5xl text-4xl font-black pb-3 text-white;
        }
        .title-2 {
            @apply md:text-5xl text-4xl font-black pb-4 text-white-light;
        }
        .content {
            @apply md:text-xl text-base pb-7 text-white-light;
        }
        .home-action-button {
            @apply md:text-base text-sm text-primary mt-2 inline-block;
        }
    }
}
