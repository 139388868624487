@import '../theme.scss';

.app-footer {
    .app-footer-link {
        transition: color 0.35s;
        cursor: pointer;
        &:hover {
            @apply text-primary;
        }
    }
    .links-wrapper {
        &> svg {
            @apply mx-4;
        }
        &>div {
            @apply hidden;
        }
        &::after {
            @apply hidden h-0 w-0;
        }
        @apply md:hidden relative flex flex-row left-0 bottom-0 text-2xl pb-2;
    }
    @apply w-full pb-3 text-center text-xs;
}