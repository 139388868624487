@import '../theme.scss';

$max-width: 1000px;

.projects-module {
    min-height: 100vh;
    .other-projects-title,
    .project-screen-title {
        max-width: $max-width;
        white-space: nowrap;
        &::after {
            content: '';
            display: block;
            height: 1px;
            opacity: 0.3;
            @apply ml-4 w-full bg-white-light;
        }
        svg {
            @apply md:text-3xl text-4xl; 
        }
        @apply md:text-3xl text-2xl  font-bold flex flex-row items-center pb-12 text-white;
    }
    .featured-projects {
        max-width: $max-width;
        @apply w-full md:mb-24 mb-8;
    }
    .other-projects {
        max-width: $max-width;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        @apply grid gap-4 w-full;
        li {
            & > div {
                @apply h-full;
                & > div {
                    @apply h-full;
                }
            }
        }
    }
    .projects-more-button {
        @apply md:text-sm text-xs text-primary mt-7 inline-block px-5;
    }
    @apply flex flex-col items-center justify-center;
}
