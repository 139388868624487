@import '../theme.scss';
.experience-module {
    min-height: 100vh;
    .experience-wrapper {
        .header {
            white-space: nowrap;
            &::after {
                content: '';
                display: block;
                height: 1px;
                opacity: 0.3;
                @apply ml-4 md:w-full bg-white-light;
            }
            svg {
                @apply md:text-5xl text-2xl; 
            }
            @apply  md:text-3xl text-2xl font-bold flex flex-row items-center pb-8 pr-4 w-full text-white;
        }
        .experience-content {
            max-width: 750px;
            @apply grid items-start justify-center;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr;
            @media only screen and (min-width: 768px) {
                grid-template-columns: auto 1fr;
                grid-template-rows: 1fr;
            }
            .org-name-wrapper {
                overflow-x: auto;
                grid-template-columns: 1fr;
                grid-template-rows: auto 1fr;
                .org-name-container {
                    .org-name {
                        transition: all 0.35s;
                        height: $tab-height;
                        width: $tab-width;
                        min-width: $tab-width;
                        &:hover {
                            @apply bg-blue-light text-primary;
                        }
                        &.active-btn {
                            @apply bg-blue-light text-primary;
                        }
                        @apply px-5 text-left text-sm;
                    }
                    @apply flex md:flex-col flex-row items-start;
                }
                .line {
                    border-radius: 2px;
                    background-color: rgba($white-light, 0.3);
                    position: relative;
                    .line-marker {
                        content: '';
                        position: absolute;
                        border-radius: 2px;
                        transition: top 0.3s, left 0.3s;
                        @apply bg-primary;
                    }
                }

                @media only screen and (min-width: 768px) {
                    grid-template-columns: auto 1fr;
                    grid-template-rows: 1fr;
                    @apply h-full;
                }
                @apply mb-5 grid overflow-x-auto;
                @apply md:mr-5 md:mb-0 md:h-full;
            }

            .experience-card {
                .title {
                    @apply font-normal text-lg pb-2;
                    .designation {
                        @apply text-white;
                    }
                    .organization {
                        cursor: pointer;
                        @apply text-primary;
                    }
                }
                .sub-title {
                    font-family: monospace;
                    @apply text-xs pb-4;
                }
                .content {
                    .content-item {
                        position: relative;
                        &::before {
                            @apply text-xs text-primary;
                            position: absolute;
                            content: '▹';
                            left: 0px;
                            top: 3px;
                            // line-height: 12px;
                        }
                        @apply pb-2 pl-5;
                    }
                    @apply text-base pb-2;
                }
                // .footer {
                // }
            }
        }
    }
    @apply flex flex-col items-center justify-center w-full;
}

.exp-tab-line {
    height: 2px;
    width: auto;
    .marker-0,
    .marker-1,
    .marker-2,
    .marker-3,
    .marker-4,
    .marker-5 {
        height: 2px;
        width: $tab-width;
        left: 0;
        top: 0;
    }
    .marker-1 {
        left: $tab-width;
    }
    .marker-2 {
        left: 2 * $tab-width;
    }
    .marker-3 {
        left: 3 * $tab-width;
    }
    .marker-4 {
        left: 4 * $tab-width;
    }
    .marker-5 {
        left: 5 * $tab-width;
    }

    @media only screen and (min-width: 768px) {
        height: 100%;
        width: 2px;
        .marker-0,
        .marker-1,
        .marker-2,
        .marker-3,
        .marker-4,
        .marker-5 {
            width: 2px;
            height: $tab-height;
            top: 0;
            left: 0;
        }
        .marker-1 {
            top: $tab-height;
        }
        .marker-2 {
            top: 2 * $tab-height;
        }
        .marker-3 {
            top: 3 * $tab-height;
        }
        .marker-4 {
            top: 4 * $tab-height;
        }
        .marker-5 {
            top: 5 * $tab-height;
        }
    }
}
