@import '../theme.scss';
.about-module {
    min-height: 100vh;
    width: 100%;
    @media only screen and (min-width: 768px) {
        grid-template-columns: 3fr 2fr;
        grid-template-rows: auto;
    }
    grid-template-columns: 1f;
    grid-template-rows: auto auto;
    .about-wrapper {
        .header {
            white-space: nowrap;
            &::after {
                content: '';
                display: block;
                height: 1px;
                opacity: 0.3;
                @apply ml-4 w-full bg-white-light;
            }
            svg {
                @apply md:text-3xl text-4xl; 
            }
            @apply md:text-3xl text-2xl font-bold flex flex-row items-center pb-8 pr-4 text-white;
        }
        @apply md:p-12;
        .content {
            p {
                @apply pb-4;
            }
            @apply text-base font-light pb-3 text-white-light;
        }
        .footer {
            .technologies-list {
                display: grid;
                grid-template-columns: repeat(2, minmax(150px, 200px));
                li {
                    @apply text-sm pl-5 flex items-center;
                    font-family: monospace;
                    position: relative;
                    &::before {
                        @apply text-xs text-primary;
                        position: absolute;
                        content: '▹';
                        left: 0px;
                        line-height: 12px;
                    }
                    @apply pb-1;
                }
            }
            @apply text-white-light;
        }
    }
    @apply grid items-center;
}
