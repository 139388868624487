@import '../theme.scss';
.app-header {
    z-index: 999;
    transition: all 0.3s;
    backdrop-filter: blur(10px);
    filter: none;
    a {
        @apply h-full object-contain;
        .app-logo {
            @apply h-full object-contain  py-3;
        }
    }
    .drawer-menu {
        z-index: 2000;
        @media only screen and (max-width: 767px) {
            &.drawer-menu-open {
                @apply w-60;
            }
            ol {
                li {
                    @apply text-base text-white-light pb-5;
                }
                @apply pl-8 pt-16;
            }
            box-shadow: -10px 0px 30px -15px $card-shadow;
            @apply w-0 h-screen top-0 right-0 fixed bg-blue-light overflow-x-hidden m-0 p-0 flex flex-col justify-between;
            transition: width 0.2s linear 0.1s;
        }
        ol {
            li {
                @apply md:list-none md:pr-8 md:pb-0 md:text-sm flex flex-row items-center;
                transition: color 0.3s;
                &.heder-active-link {
                    @apply text-primary;
                }
                &:hover {
                    @apply text-primary;
                }
                &:last-child {
                    @apply pr-0;
                }
                .menu-io-icon {
                    @apply text-sm mr-1.5;
                }
            }
            @apply md:flex md:flex-row md:float-left md:pr-0 md:pl-0 md:py-0;
        }
        .links-wrapper {
            &>div {
                @apply hidden;
            }
            &::after {
                @apply hidden h-0 w-0;
            }
            @apply md:hidden relative flex flex-row left-0 bottom-0 text-2xl pb-8 justify-between px-8;
        }
        @apply md:relative;
    }
    .background-cover-layer {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100vh;
        filter: brightness(0.6);
        background-color: #0a192fa6;
        @apply overflow-x-hidden m-0 p-0 top-0 left-0;
        @apply md:hidden md:w-0 md:h-0;
    }

    // hamberger menu icon
    .hamberger-menu {
        height: 29px;
        width: 29px;
        position: relative;
        z-index: 2001;
        span {
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease;
            -moz-transition: 0.25s ease;
            -o-transition: 0.25s ease;
            transition: 0.25s ease;

            @apply bg-primary;
            height: 3px;
            width: 100%;
            position: absolute;
        }
        span:nth-child(1) {
            top: 0px;
        }
        span:nth-child(2),
        span:nth-child(3) {
            top: 10px;
        }
        span:nth-child(4) {
            top: 20px;
        }
        &.open {
            span:nth-child(1) {
                top: 10px;
                width: 0%;
                left: 50%;
            }
            span:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            span:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            span:nth-child(4) {
                top: 10px;
                width: 0%;
                left: 50%;
            }
        }
        @apply md:hidden block;
    }
    @apply w-full h-16 flex items-center justify-between px-8 bg-blue-blur left-0 top-0 fixed filter-none;
}
